@keyframes BgChanged {
    0%   {filter: blur(0)}
    25%  {filter: blur(1px)}
    50%  {filter: blur(2px)}
    100% {filter: blur(3px)}
}
.NewsCard{
    position: relative;
}
.NewsCard:hover .ShowDetail{
    animation: BgChanged 1s;
    animation-fill-mode: forwards;
}
@keyframes ButtonChanged {
    0%   {visibility: hidden;filter: blur(4px)}
    25%  {visibility:visible;filter: blur(3px)}
    50%  {visibility:visible;filter: blur(2px)}
    100% {visibility:visible;filter: blur(1)}
}
.NewsCard:hover .ToDetail{
    position: absolute;
    animation: ButtonChanged 600ms;
    animation-fill-mode: forwards;
}

.ToDetail{
    position: absolute;
    visibility: hidden;
    top:50%;
    left: 50%;
    z-index: 100;
}

